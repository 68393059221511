import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title='Contact' />
    <h1>Do you have a question?</h1>
    <p>Write your request to</p>
    <p>
      <a href='mailto:patrick.schlager@vispecs.com'>
        patrick.schlager@vispecs.com
      </a>
    </p>
    <p>or</p>
    <p>
      <a href='patrick.schlager@vispecs.com'>christoph.pichler@vispecs.com</a>
    </p>
  </Layout>
)

export default ContactPage
